@font-face {
  font-family: 'manroperegular';
  src: url('../public/fonts/manrope-regular-webfont.woff2') format('woff2'),
    url('../public/fonts/manrope-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'alex_brushregular';
  src: url('../public/fonts/alexbrush-regular-webfont.woff2') format('woff2'),
    url('../public/fonts/alexbrush-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

.landing {
  height: 100vh;
  background: linear-gradient(180deg, #1169FE 0%, #1C3D5B 100%);
}

.logo {
  font-family: 'manroperegular';
  font-size: 42.25px !important;
}

.discovery {
  font-family: 'alex_brushregular';
  font-style: normal;
  font-weight: 400 !important;
  color: rgba(255, 255, 255, 0.69);
}

.collection {
  font-family: 'Abhaya Libre';
  font-style: normal;
  font-weight: 400 !important;
}

.speech {
  font-family: 'Abhaya Libre';
  font-style: normal;
  font-weight: 400;
}

.marg {
  margin-bottom: 10%;

}

.b1 {
  width: 200.54px;
  height: 400px;
  bottom: -3rem;
  left: 5rem;
  border-radius: 600px;
  background-size: cover;
}

.b2 {
  width: 120.48px;
  height: 320.45px;
  bottom: 4rem;
  left: 190px;
  background: linear-gradient(180deg, rgba(245, 0, 191, 0.424) 0%, rgba(25, 231, 157, 0.128) 100%);
  border-radius: 350px;
}

.b3 {
  width: 93.27px;
  height: 236.69px;
  top: 5rem;
  left: 20px;
  border: 3px solid #FFFFFF;
  border-radius: 300px;
}

.b4 {
  top: 200px;
  left: -56px;
  width: 430.81px;
  height: 0;
  border-top: 3px solid #FFFFFF;
  transform: matrix(-0.75, 0.7, -0.64, -0.74, 0, 0);
}

.about {
  background: linear-gradient(180deg, #1169FE 0%, #1C3D5B 100%);
}

.ptitcadre {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.form-control:focus {
  box-shadow: 0 0 2px 2px rgba(4, 3, 3, 0.164) !important;
}

.navbar {
  background-color: transparent;
}

.navbar.colorChange {
  background-color: #000;
}

.btn-service {
  display: inline-block;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 1em;
  border: 2px solid #0A2640;
  border-radius: 10em;
  background-color: #0A2640;
  color: white;
  padding: 0.5em 2em;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  transition: none;
  pointer-events: none;
}

.confondu-header {
  color: white !important;
  mix-blend-mode: lighten;
}

.confondu-content {
  color: white;
  font-weight: lighter;
}

/* gestion menu tablette et plus */
@media screen and (min-width:768px) {
  .get-st {
    text-align: center;
    display: block;
    margin: auto;
    padding: 1rem 1.25rem;
    font-family: sans-serif;
    text-decoration: none;
    color: white !important;
    position: relative;
    z-index: 1;
    background-color: #16437E;
  }

  .get-st:hover,
  .get-st:focus {
    background-color: #16437E;
    color: #ffffff !important;
  }

  .get-st:active {
    background: #09223b;
    color: #1111 !important;
  }

  .get-st:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
  }
}
.myfs{
  font-size: 1rem !important;
}
@media screen and (max-width:767px) {
  
  .get-st {
    text-align: center;
    display: block;
    margin: auto;
    padding: 1rem 1.25rem;
    font-family: sans-serif;
    text-decoration: none;
    color: white !important;
    position: relative;
    z-index: 1;
    background-color: #16437E;
  }

  .mym {
    margin-bottom: 2rem !important;
  }

  .get-st:active {
    background: white !important;
    color: white !important;
  }

  .get-st:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
  }

  .activation {
    text-align: center;
    display: block;
    margin: auto;
    padding: 1rem 1.25rem;
    font-family: sans-serif;
    text-decoration: none;
    color: white !important;
    position: relative;
    transition: all .3s cubic-bezier(.2, 0, 0, 1);
    z-index: 1;
  }
  .activation::after {
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    bottom: 0;
    background-color: #fff !important;
    right: 1.25rem;
    left: 1.25rem;
    transition: all .3s cubic-bezier(.2, 0, 0, 1);
    transform-origin: bottom center;
    z-index: -1;
  }
  .text-me {
    display: block;
    margin: auto;
    padding: 1rem 1.25rem;
    font-family: sans-serif;
    text-decoration: none;
    color: white !important;
    position: relative;
    transition: all .3s cubic-bezier(.2, 0, 0, 1);
    z-index: 1;
  
  }
  
}
@media screen and (min-width:768px) {
  .text-me {
    display: block;
    margin: auto;
    padding: 1rem 1.25rem;
    font-family: sans-serif;
    text-decoration: none;
    color: white !important;
    position: relative;
    transition: all .3s cubic-bezier(.2, 0, 0, 1);
    z-index: 1;
  
  }
  
  .activation {
    display: block;
    margin: auto;
    padding: 1rem 1.25rem;
    font-family: sans-serif;
    text-decoration: none;
    color: white !important;
    position: relative;
    transition: all .3s cubic-bezier(.2, 0, 0, 1);
    z-index: 1;
  }
  
  .activation::after {
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    bottom: 0;
    background-color: white !important;
    right: 1.25rem;
    left: 1.25rem;
    transition: all .3s cubic-bezier(.2, 0, 0, 1);
    transform-origin: bottom center;
    z-index: -1;
  }
}



/* fin gestion des menu tablettes et plus */
/* gestion menu en dessous de tablette */

/*fin gestion menu en dessous de tablette */


.centre {
  vertical-align: middle;
}

.taille-icon {
  font-size: 2em;
}


.monactiv {
  scale: "1.1";
  background-color: #ccddff;
}

.brand {
  color: white !important;
  font-size: 2rem !important;

}

.brand:active {
  color: white !important;
  font-size: 2rem !important;

}
.overr{
  color: white !important;
}
.overr:hover{
  color: white !important;
}
.single_slider p{
  text-decoration: none !important;
}
/* dropdown */
.dropdownp {
  padding: 1rem;
  display: none;
}
@media screen and (min-width:768px) {

  .princ {
    position: relative;
  }
  .princ:hover .dropdownp {
    background-color: white;
    position: absolute;
    display: block;
    min-width: 250px;
    text-align: left;
  }

  

  .princ:hover .dropdownp li:hover {
    border-left: 2px solid #3ca0e7;
  }

 

  .princ:hover .dropdownp li {
    padding: 0.5em;
  }
}



@media screen and (max-width:767px) {
  
  .dropi {
    display: none;
  }
  .princ:hover .dropdownp {
    background-color: white;
    display: block;
    min-width: 250px;
    text-align: left;
  }

  

  .princ:hover .dropdownp li:hover {
    border-left: 2px solid #3ca0e7;
  }

  .englobe {
    padding-top: 0;
  }

  .princ:hover .dropdownp li {
    padding: 0.5em;
  }
  }
.confirmation{
  position: relative;
}
.confirmation::after{
  position: absolute;
  content: '';
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(66, 181, 66, 0.53);
  border-radius: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  visibility: hidden;
}
